import React, { useState, useEffect } from 'react';
import {
	CModal,
	CModalHeader,
	CModalBody,
	CModalFooter,
	CButton,
	CRow,
} from '@coreui/react';

const ConfirmDialog = (props) => {
	const [show, setShow] = useState(props.show);
	const [loading, setLoading] = useState(props.loading);

	useEffect(() => {
		setShow(props.show);
	}, [props.show]);

	useEffect(() => {
		setLoading(props.loading);
	}, [props.loading]);

	const cancel = (e) => {
		if (props.cancel) {
			props.cancel(e);
		}
	};

	return (
		<>
			<div
				onClick={() => {
					setShow((show) => !show);
				}}
			>
				{props.children}
			</div>
			<CModal
				show={show}
				onClose={props.onClose ? props.onClose : cancel}
				color={props.color ? props.color : ''}
			>
				<CModalHeader closeButton>
					<h4 style={{ marginBottom: '0px' }}>
						{props.title ? props.title : 'Action Confirmation'}
					</h4>
				</CModalHeader>
				<CModalBody>
					{props.message ? props.message : 'Are you sure about this action?'}
				</CModalBody>
				<CModalFooter>
					<CRow className='d-flex' style={{ gap: '12px' }}>
						<CButton
							size='sm'
							color={props.cancelColor ? props.cancelColor : 'info'}
							onClick={(e) => {
								setShow((show) => !show);
								cancel(e);
							}}
						>
							{props.cancelText ? props.cancelText : 'No'}
						</CButton>
						<CButton
							size='sm'
							color={props.confirmColor ? props.confirmColor : 'success'}
							onClick={(e) => {
								if (
									props.dismissOnConfirm != null ? props.dismissOnConfirm : true
								) {
									setShow((show) => !show);
								}
								props.confirm(e);
							}}
						>
							{loading && (
								<span className='spinner-border spinner-border-sm mr-1'></span>
							)}
							<span>{props.confirmText ? props.confirmText : 'Yes'}</span>
							{props.confirmSubText && (
								<span>
									<br />
									<small>{props.confirmSubText}</small>
								</span>
							)}
						</CButton>
					</CRow>
				</CModalFooter>
			</CModal>
		</>
	);
};

export default ConfirmDialog;
