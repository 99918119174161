import React, { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import ConfirmDialog from '../dialog/ConfirmDialog';
import { history } from 'src/helpers/history';
import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAutoSaveFunc } from 'src/stores/panel/panel.actions';

const SessionTimer = () => {
	const autoLogoutTimeout = 1000 * 60 * 30;
	const promptTimeout = 1000 * 60;

	const dispatch = useDispatch();
	const [showPrompt, setShowPrompt] = useState(false);
	const [loading, setLoading] = useState(false);
	const autoSaveFunc = useSelector((state) => state.panel.autoSaveFunc);

	const onPrompt = () => {
		setShowPrompt(true);
	};

	const onIdle = useCallback(() => {
		if (autoSaveFunc) {
			setLoading(true);
			autoSaveFunc(null, () => {
				setLoading(false);
				setShowPrompt(false);
				history.push('/logout');
			});
			dispatch(setAutoSaveFunc(null));
		} else {
			setShowPrompt(false);
			history.push('/logout');
		}
	}, [autoSaveFunc, dispatch]);

	const onAction = (event) => {
		resetLastActive();
	};

	const onActive = (event) => {
		resetLastActive();
	};

	const resetLastActive = () => {
		localStorage.setItem('last_active', JSON.stringify(Date.now()));
	};

	useEffect(() => {
		const lastActive = JSON.parse(localStorage.getItem('last_active')) || null;
		if (lastActive != null) {
			const lastActiveDate = new Date(parseInt(lastActive));
			if (Date.now() - lastActiveDate >= autoLogoutTimeout) {
				onIdle();
			}
		}
	}, [autoLogoutTimeout, onIdle]);

	const idleTimer = useIdleTimer({
		onPrompt,
		onIdle,
		onActive,
		onAction,
		timeout: autoLogoutTimeout,
		promptTimeout: promptTimeout,
		crossTab: true,
	});

	const onClosePrompt = () => {
		setShowPrompt(false);
		idleTimer.reset();
	};

	return (
		<>
			<ConfirmDialog
				show={showPrompt}
				confirm={onIdle}
				cancel={onClosePrompt}
				message='Your page has been inactive for some time. Are you still there?'
				title='Auto Logout'
				cancelText='Yes, I’m still here.'
				confirmText='No, log me out.'
				confirmSubText={
					autoSaveFunc ? 'Changes will be saved before logging out.' : null
				}
				loading={loading}
				cancelColor='success'
				confirmColor='danger'
				dismissOnConfirm={false}
			/>
		</>
	);
};

export default SessionTimer;
