import { panelConstants } from './panel.constants';

const initialState = {
	sidebarShow: 'responsive',
	autoSaveFunc: null,
};

const panel = (state = initialState, { type, ...rest }) => {
	switch (type) {
		case panelConstants.SET_PANEL_CONFIG:
			return { ...state, ...rest };
		case panelConstants.SET_AUTO_SAVE_FUNC:
			return { ...state, ...rest };
		default:
			return state;
	}
};

export default panel;
