import { panelConstants } from './panel.constants';

export const setSidebarShow = (value) => {
	return {
		type: panelConstants.SET_PANEL_CONFIG,
		sidebarShow: value,
	};
};

export const setAutoSaveFunc = (value) => {
	return {
		type: panelConstants.SET_AUTO_SAVE_FUNC,
		autoSaveFunc: value,
	};
};
