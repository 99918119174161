import React, { Component } from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import './scss/style.scss';
import PrivateRoute from './components/private-route/PrivateRoute';
import { history } from './helpers/history';
import SessionTimer from './components/auto-logout/SessionTimer';

const loading = (
	<div className='pt-3 text-center'>
		<div className='sk-spinner sk-spinner-pulse'></div>
	</div>
);

// Components
const MainLayout = React.lazy(() => import('./components/coreui/MainLayout'));

// Pages
const Login = React.lazy(() => import('./views/auth/Login'));
const Logout = React.lazy(() => import('./views/auth/Logout'));
const NotFound = React.lazy(() => import('./views/errors/NotFound'));
const Error = React.lazy(() => import('./views/errors/Error'));

class App extends Component {
	render() {
		return (
			<Router history={history}>
				<React.Suspense fallback={loading}>
					<Switch>
						<Route
							exact
							path='/error'
							name='Error Page'
							render={(props) => <Error {...props} />}
						/>
						<Route
							exact
							path='/404'
							name='Not Found Page'
							render={(props) => <NotFound {...props} />}
						/>
						<Route
							exact
							path='/login'
							name='Login Page'
							render={(props) => <Login {...props} />}
						/>
						<Route
							exact
							path='/logout'
							name='Login Page'
							render={(props) => <Logout {...props} />}
						/>
						<PrivateRoute
							path='/'
							name='Home'
							component={() => (
								<>
									<MainLayout />
									<SessionTimer />
								</>
							)}
						/>
					</Switch>
				</React.Suspense>
			</Router>
		);
	}
}

export default App;
